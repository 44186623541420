import React from 'react'

function Volksfest () {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="290"
            height="60"
            viewBox="0 0 290 60"
    >
            <g fill="#01396F" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(61 -49)">
                    <path d="M26.4344277 58.6119403L18.3170479 85.8695374 18.1570666 85.8695374 9.93287926 58.6119403 0 58.6119403 0 59.1440992 12.3894547 94.8134328 23.9778522 94.8134328 36.3136729 59.1440992 36.3136729 58.6119403z"></path>
                    <path d="M49.452 88.998c3.022 0 5.143-1.86 5.143-6.646 0-4.784-2.121-6.645-5.143-6.645-2.917 0-5.25 1.86-5.25 6.645 0 4.785 2.333 6.646 5.25 6.646m0 6.965c-9.438 0-14.105-6.168-14.105-13.61 0-7.444 4.667-13.611 14.105-13.611 9.597 0 13.998 6.167 13.998 13.61s-4.401 13.61-13.998 13.61"></path>
                    <path d="M76.493 57.95v30.41c0 .798.213 1.063 1.008 1.063h2.28v5.795c-1.538.266-3.128.479-4.878.479-5.143 0-7.052-2.286-7.052-7.815V57.949h8.642z"></path>
                    <path d="M92.4005119 83.3092949L92.4005119 95.1652324 83.7046184 95.1652324 83.7046184 57.9494824 92.4005119 57.9494824 92.4005119 80.8104616 92.5065594 80.8104616 100.406868 69.5395866 109.791613 69.5395866 109.791613 70.0710241 100.990129 81.8206282 110.268827 94.6335657 110.268827 95.1652324 100.565711 95.1652324 92.5065594 83.3092949z"></path>
                    <path d="M114.087 86.978c2.704 1.329 5.992 2.392 8.908 2.392 1.962 0 3.181-.425 3.181-1.648 0-1.01-.954-1.382-3.287-1.86l-2.863-.586c-4.401-.903-7.53-2.924-7.53-7.868 0-5.423 4.136-8.666 11.4-8.666 4.083 0 6.734.585 9.969 1.914l-2.28 6.38c-2.174-.904-5.303-1.702-7.689-1.702-2.12 0-3.022.479-3.022 1.542 0 .851.742 1.276 2.97 1.755l2.81.585c4.56.957 7.794 3.349 7.794 7.974 0 5.689-4.454 8.773-11.453 8.773-4.4 0-8.007-.957-11.294-2.605l2.386-6.38z"></path>
                    <path d="M142.083 95.165v-23.34h-4.56v-1.86l4.56-.425v-4.36c0-5.157 2.333-7.496 7.211-7.496 1.06 0 2.174.106 3.075.319v2.392c-.901-.213-1.962-.32-3.022-.32-3.288 0-4.719 1.224-4.719 4.839v4.626h7.53v2.285h-7.53v23.34h-2.545z"></path>
                    <path d="M184.236 91.39c1.856 1.383 4.136 2.18 7.423 2.18 4.242 0 6.575-1.86 6.575-4.944 0-2.127-1.272-3.669-5.25-4.838l-3.18-.957c-3.871-1.17-6.098-3.137-6.098-6.7 0-4.252 3.128-7.39 8.855-7.39 3.022 0 5.567.745 7.794 1.809l-.954 2.286c-2.333-1.276-4.666-1.702-6.787-1.702-4.242 0-6.257 1.968-6.257 4.945 0 1.914 1.008 3.402 4.136 4.306l3.181.957c4.878 1.436 7.211 3.35 7.211 7.23 0 4.094-3.181 7.39-9.172 7.39-3.871 0-6.416-1.01-8.643-2.445l1.166-2.127z"></path>
                    <path d="M212.02 63.585v5.954h6.204v2.287h-6.204v18.501c0 2.127.902 3.243 2.81 3.243 1.273 0 2.652-.319 3.447-.585v2.34c-1.007.319-2.12.638-3.659.638-3.446 0-5.143-2.127-5.143-5.742V71.826h-4.56v-1.861l4.56-.426v-5.954h2.545z"></path>
                    <path d="M158.255 80.545h16.808c.053-.532.053-1.01.053-1.383 0-4.625-2.598-8.028-7.953-8.028-4.932 0-8.325 3.51-8.908 9.41zm-.053 2.286c.159 6.22 3.075 10.74 9.544 10.74 3.446 0 5.673-1.17 7.635-2.553l1.167 2.02c-2.121 1.543-4.666 2.925-8.855 2.925-8.484 0-12.09-5.955-12.09-13.717 0-7.762 4.56-13.504 11.56-13.504 7.158 0 10.604 4.998 10.604 10.58 0 1.116-.159 2.499-.371 3.509h-19.194z"></path>
                </g>
                <g transform="translate(-39 3)">
                    <g transform="translate(41.155)">
                        <path d="M25.592 51.992c14.178 0 25.592-11.594 25.592-25.996S39.77 0 25.592 0C11.414 0 0 11.594 0 25.996s11.414 25.996 25.592 25.996M18.17 37.746c-.153 0-.204-.156-.307-.312L6.091 13.31c2.098-3.276 4.914-6.031 8.343-7.955l8.497 19.185c.102.312.358.416.614.416h4.095c.307 0 .511-.052.665-.416L36.8 5.355c3.379 1.924 6.245 4.68 8.344 7.955L33.27 37.434c-.051.156-.154.312-.307.312-.154 0-.205-.156-.307-.312L28.203 27.14c-.154-.364-.359-.416-.666-.416h-4.095c-.307 0-.511.052-.665.416l-4.3 10.294c-.05.156-.153.312-.307.312m7.422-17.105c-.153 0-.205-.156-.307-.312L18.017 3.69a21.529 21.529 0 017.575-1.351c2.662 0 5.22.468 7.575 1.351L25.9 20.33c-.102.208-.153.312-.307.312m0 28.96c-12.745 0-23.186-10.66-23.186-23.605 0-2.912.511-5.667 1.484-8.267l13.564 27.712c.153.364.41.676.819.676.41 0 .665-.312.819-.676l6.244-14.194c.051-.156.154-.312.307-.312.154 0 .205.156.307.312l6.245 14.194c.153.364.41.676.819.676.41 0 .665-.312.819-.676l13.564-27.712a23.414 23.414 0 011.484 8.267C48.779 38.942 38.337 49.6 25.592 49.6"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Volksfest
