// @flow
import * as React from 'react'
import styles from './styles.module.scss'
import cn from 'classnames'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes.js'

function MainLogo (props) {
    // color={props.eventColor || props.theme.eventColor}
    return (
        <div className={cn(styles.container, styles.logo, props.className)} style={props.style}>
            <svg width={489} height={87} viewBox="0 0 489 87" {...props}>
            <title>{"LOGO - FordEmployeePricing"}</title>
            <defs>
                <path id="prefix__a" d="M.417.112h62.87v60.906H.418z" />
                <path id="prefix__c" d="M.082.112h39.857v60.906H.082z" />
                <path id="prefix__e" d="M.38.367h29.893V72.81H.38z" />
            </defs>
            <g fill="none" fillRule="evenodd">
                <path
                fill="#FFF"
                d="M59.27 3L41.11 63.988h-.357L22.353 3H.131v1.19L27.85 84h25.926L81.373 4.19V3z"
                />
                <g transform="translate(78.794 25.554)">
                <mask id="prefix__b" fill="#fff">
                    <use xlinkHref="#prefix__a" />
                </mask>
                <path
                    d="M31.971 45.434c6.762 0 11.507-4.163 11.507-14.869s-4.745-14.87-11.507-14.87c-6.524 0-11.744 4.164-11.744 14.87s5.22 14.87 11.744 14.87m0 15.583c-21.115 0-31.554-13.8-31.554-30.453C.417 13.911 10.857.112 31.971.112c21.47 0 31.317 13.799 31.317 30.453S53.442 61.018 31.97 61.018"
                    fill="#FFF"
                    mask="url(#prefix__b)"
                />
                </g>
                <path
                d="M171.263 1.518V69.56c0 1.784.475 2.379 2.254 2.379h5.101v12.966c-3.44.595-6.999 1.07-10.913 1.07-11.507 0-15.777-5.115-15.777-17.486V1.518h19.335zM206.85 58.26v26.527h-19.454V1.517h19.455V52.67h.237l17.675-25.219h20.995v1.19l-19.69 26.289 20.758 28.669v1.19h-21.708l-18.03-26.528zM255.368 66.468c6.05 2.974 13.405 5.352 19.929 5.352 4.39 0 7.117-.951 7.117-3.687 0-2.26-2.135-3.093-7.354-4.163l-6.406-1.309c-9.845-2.022-16.844-6.543-16.844-17.605 0-12.134 9.252-19.39 25.504-19.39 9.133 0 15.065 1.308 22.3 4.282l-5.1 14.275c-4.863-2.022-11.863-3.807-17.2-3.807-4.745 0-6.762 1.07-6.762 3.45 0 1.903 1.66 2.855 6.643 3.926l6.287 1.308c10.202 2.142 17.438 7.494 17.438 17.844 0 12.728-9.964 19.627-25.623 19.627-9.845 0-17.912-2.14-25.266-5.829l5.337-14.274zM318 84.787V32.565h-10.2v-4.163l10.2-.952v-9.754C318 6.156 323.22.923 334.135.923c2.372 0 4.863.238 6.88.714v5.352c-2.017-.475-4.39-.713-6.762-.713-7.355 0-10.557 2.736-10.557 10.825v10.35h16.844v5.114h-16.844v52.222H318zM354.18 52.074h37.604c.118-1.19.118-2.26.118-3.093 0-10.35-5.812-17.962-17.792-17.962-11.033 0-18.624 7.851-19.93 21.055zm-.117 5.115c.355 13.918 6.88 24.03 21.351 24.03 7.71 0 12.693-2.618 17.082-5.71l2.61 4.52c-4.745 3.45-10.44 6.542-19.81 6.542-18.98 0-27.047-13.323-27.047-30.69 0-17.368 10.202-30.215 25.86-30.215 16.015 0 23.725 11.182 23.725 23.672 0 2.498-.356 5.591-.83 7.851h-42.941z"
                fill="#FFF"
                />
                <g transform="translate(409.615 25.554)">
                <mask id="prefix__d" fill="#fff">
                    <use xlinkHref="#prefix__c" />
                </mask>
                <path
                    d="M2.692 50.788c4.152 3.092 9.252 4.877 16.607 4.877 9.49 0 14.71-4.164 14.71-11.063 0-4.758-2.848-8.208-11.745-10.825l-7.117-2.141c-8.66-2.617-13.641-7.019-13.641-14.99C1.506 7.132 8.504.113 21.316.113c6.761 0 12.455 1.665 17.437 4.045l-2.135 5.115c-5.22-2.855-10.439-3.807-15.184-3.807-9.49 0-13.997 4.402-13.997 11.063 0 4.283 2.254 7.613 9.252 9.635l7.117 2.142C34.72 31.517 39.94 35.799 39.94 44.483c0 9.16-7.117 16.535-20.521 16.535-8.66 0-14.353-2.26-19.336-5.472l2.61-4.758z"
                    fill="#FFF"
                    mask="url(#prefix__d)"
                />
                </g>
                <g transform="translate(458.19 13.76)">
                <mask id="prefix__f" fill="#fff">
                    <use xlinkHref="#prefix__e" />
                </mask>
                <path
                    d="M16.275.367V13.69h13.88v5.115h-13.88v41.397c0 4.758 2.017 7.256 6.288 7.256 2.847 0 5.93-.714 7.71-1.308v5.233c-2.254.715-4.745 1.428-8.185 1.428-7.71 0-11.507-4.758-11.507-12.847v-41.16H.381v-4.163l10.2-.951V.367h5.694z"
                    fill="#FFF"
                    mask="url(#prefix__f)"
                />
                </g>
            </g>
            </svg>
        </div>
    )
}

export default withTheme(themes)(MainLogo)
