
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns95efca2a0722571aeac01257dd8f0478en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '95efca2a0722571aeac01257dd8f0478', flattenObject(ns95efca2a0722571aeac01257dd8f0478en_US), true, true);
        
            import ns95efca2a0722571aeac01257dd8f0478en from './en.yaml'
            i18n.addResourceBundle('en', '95efca2a0722571aeac01257dd8f0478', flattenObject(ns95efca2a0722571aeac01257dd8f0478en), true, true);
        
        export default '95efca2a0722571aeac01257dd8f0478';
    