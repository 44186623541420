// @flow
import React from 'react'
import Modal from 'components/Modal'

import styles from './styles.module.scss'

export default function LegalDisclaimer () {
    const [open, setOpen] = React.useState(false)

    return <>
        <button className={styles.button} onClick={() => setOpen(true)}>Legal Disclaimer</button>
        <Modal isOpen={open} onCancel={() => setOpen(false)}>
            <div className={styles.content}>
                <p>*Trade in bonus is available for all vehicles traded in during offer period. Bonus will be applied to purchase or lease of a new VW vehicle, on top of appraised vehicle trade-in value. Conditions apply. See dealer for details. Offer available from October 22 to 24, 2020 and is subject to change or cancellation without notice. $500 trade-in bonus)</p>
                <p>* Limited time finance offer available through Volkswagen Finance, on approved credit, on select new,
                unregistered models. Finance example based on a 2020 Tiguan Trendline FWD model with 8-speed automatic
                transmission. Base MSRP of $29,770, plus $1,885 freight and PDI and $100 air-conditioning levy, financed at 0%
                APR for 72 months with monthly payments starting from $442. $0 down payment or equivalent trade-in due at
                signing. Cost of borrowing is $0 for a total obligation of $31,780. PPSA fee, license, insurance, registration, any
                dealer or other charges, options, environmental levies and applicable taxes are extra.
                ^3 yr/45,000 km VW Prepaid Maintenance Plan coverage will be effective as of the date of delivery but plan
                documentation will be issued by dealer by Nov. 1. See dealer for details. Plan provided at no additional cost with
                cash purchase, finance or lease of new and unregistered 2021 Atlas / 2020 Tiguan, Atlas Cross Sport / 2019 Arteon,
                Golf R, Golf Sportwagen, Golf Alltrack models. Plan covers maintenance appointments (for services recommended
                by VW) within 3 years or 45,000 km (whichever occurs first). See owner’s manual for details of services
                recommended at time / km intervals.  Some exclusions apply, including any exceptional services not covered by
                warranty. Ask your dealer for details.</p>
                <p>**Up to $1,000/$500 credit available to be applied as a discount off MSRP with cash purchase, finance purchase or
                lease of new, unregistered 2020 Atlas Cross Sport, Tiguan and 2021 Atlas / 2020 Golf, GTI, e-Golf, Jetta, Passat and
                Arteon models. Discount varies by model.
                Dealer may sell or lease for less. Dealer order/trade may be necessary. Offer expires November 2, 2020 and is
                subject to change or cancellation without notice. Conditions apply. Visit vwoffers.ca or your Volkswagen dealer for
                details. “Volkswagen,” the Volkswagen logo, and all model names are registered trademarks or trademarks of
                Volkswagen AG. © 2020 Volkswagen Canada.</p>
            </div>
        </Modal>
    </>
}
