
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import nsfe1f03ea0012bede3ed745c77d6fb9a1en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', 'fe1f03ea0012bede3ed745c77d6fb9a1', flattenObject(nsfe1f03ea0012bede3ed745c77d6fb9a1en_US), true, true);
        
            import nsfe1f03ea0012bede3ed745c77d6fb9a1en from './en.yaml'
            i18n.addResourceBundle('en', 'fe1f03ea0012bede3ed745c77d6fb9a1', flattenObject(nsfe1f03ea0012bede3ed745c77d6fb9a1en), true, true);
        
        export default 'fe1f03ea0012bede3ed745c77d6fb9a1';
    