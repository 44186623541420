// @flow

import * as React from 'react'

type Props = {}

const Volkfest = React.forwardRef(function (props: Props, ref: React.Ref) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="190"
            height="69"
            viewBox="0 0 190 69"
            ref={ref}
    >
            <g fill="#01396F" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g transform="translate(34 -19)">
                    <path d="M13.4016401 29.6865672L9.28631731 43.4923631 9.20521053 43.4923631 5.03573879 29.6865672 0 29.6865672 0 29.9561022 6.28116541 48.0223881 12.1562134 48.0223881 18.4101877 29.9561022 18.4101877 29.6865672z"></path>
                    <path d="M25.07 45.077c1.533 0 2.608-.943 2.608-3.366 0-2.424-1.075-3.366-2.607-3.366-1.479 0-2.662.942-2.662 3.366 0 2.423 1.183 3.366 2.662 3.366m0 3.528c-4.785 0-7.15-3.124-7.15-6.894s2.365-6.894 7.15-6.894c4.865 0 7.096 3.124 7.096 6.894s-2.23 6.894-7.096 6.894"></path>
                    <path d="M38.78 29.351v15.403c0 .404.108.538.511.538h1.156v2.935c-.78.135-1.586.243-2.473.243-2.607 0-3.575-1.158-3.575-3.959v-15.16h4.381z"></path>
                    <path d="M46.8449107 42.1956169L46.8449107 48.2005723 42.4362949 48.2005723 42.4362949 29.3510365 46.8449107 29.3510365 46.8449107 40.929974 46.8986743 40.929974 50.9039471 35.221349 55.6617946 35.221349 55.6617946 35.4905187 51.199647 41.4416169 55.9037309 47.9312865 55.9037309 48.2005723 50.9844767 48.2005723 46.8986743 42.1956169z"></path>
                    <path d="M57.84 44.054c1.37.673 3.037 1.211 4.516 1.211.994 0 1.613-.215 1.613-.834 0-.512-.484-.7-1.667-.943l-1.452-.296c-2.23-.458-3.817-1.481-3.817-3.985 0-2.747 2.097-4.39 5.78-4.39 2.07 0 3.413.297 5.053.97l-1.156 3.231c-1.102-.458-2.688-.862-3.897-.862-1.076 0-1.533.243-1.533.781 0 .431.377.647 1.506.889l1.424.296c2.312.485 3.952 1.697 3.952 4.04 0 2.88-2.258 4.442-5.806 4.442-2.231 0-4.06-.484-5.726-1.319l1.21-3.231z"></path>
                    <path d="M72.033 48.2V36.38H69.72v-.943l2.312-.216v-2.208c0-2.612 1.183-3.797 3.656-3.797.537 0 1.102.054 1.559.162v1.212a6.867 6.867 0 00-1.533-.162c-1.666 0-2.392.62-2.392 2.45v2.343h3.817v1.158h-3.817v11.822h-1.29z"></path>
                    <path d="M93.403 46.289c.941.7 2.097 1.104 3.764 1.104 2.15 0 3.333-.943 3.333-2.505 0-1.077-.645-1.858-2.661-2.45l-1.613-.485c-1.962-.592-3.091-1.589-3.091-3.393 0-2.154 1.586-3.743 4.489-3.743 1.532 0 2.822.377 3.951.916l-.483 1.158a7.072 7.072 0 00-3.441-.862c-2.15 0-3.172.996-3.172 2.504 0 .97.51 1.724 2.097 2.182l1.612.484c2.473.727 3.656 1.697 3.656 3.663 0 2.073-1.613 3.743-4.65 3.743-1.963 0-3.253-.512-4.382-1.24l.591-1.076z"></path>
                    <path d="M107.49 32.205v3.016h3.145v1.158h-3.146v9.371c0 1.077.457 1.643 1.425 1.643.645 0 1.344-.162 1.747-.296v1.184c-.51.162-1.075.324-1.854.324-1.748 0-2.608-1.078-2.608-2.909V36.38h-2.312v-.942l2.312-.216v-3.016h1.29z"></path>
                    <path d="M80.232 40.795h8.52c.028-.269.028-.511.028-.7 0-2.343-1.317-4.066-4.032-4.066-2.5 0-4.22 1.777-4.516 4.766zm-.027 1.158c.08 3.15 1.559 5.44 4.838 5.44 1.747 0 2.877-.593 3.871-1.293l.592 1.023c-1.076.781-2.366 1.481-4.49 1.481-4.3 0-6.129-3.015-6.129-6.947 0-3.931 2.312-6.84 5.86-6.84 3.63 0 5.377 2.532 5.377 5.359 0 .566-.08 1.266-.188 1.777h-9.731z"></path>
                </g>
                <g transform="translate(-16 7)">
                    <g transform="translate(21.35)">
                        <path d="M13.277 25.996c7.356 0 13.277-5.797 13.277-12.998C26.554 5.798 20.633 0 13.277 0 5.922 0 0 5.797 0 12.998c0 7.2 5.922 12.998 13.277 12.998m-3.85-7.123c-.08 0-.106-.078-.16-.156L3.16 6.655a12.118 12.118 0 014.328-3.977l4.408 9.592c.053.156.186.208.319.208h2.124c.16 0 .266-.026.345-.208l4.409-9.592c1.752.961 3.24 2.34 4.328 3.977l-6.16 12.062c-.027.078-.08.156-.16.156s-.106-.078-.16-.156l-2.31-5.147c-.08-.182-.185-.208-.345-.208h-2.124c-.16 0-.266.026-.345.208l-2.23 5.147c-.027.078-.08.156-.16.156m3.85-8.553c-.08 0-.106-.078-.16-.156l-3.77-8.318a11.544 11.544 0 013.93-.676c1.38 0 2.709.234 3.93.676l-3.77 8.318c-.054.104-.08.156-.16.156m0 14.48c-6.612 0-12.029-5.33-12.029-11.802 0-1.456.266-2.834.77-4.133L9.055 22.72c.08.182.212.338.425.338.212 0 .345-.156.425-.338l3.24-7.096c.026-.078.079-.156.159-.156s.106.078.159.156l3.24 7.096c.08.182.212.338.424.338.213 0 .346-.156.425-.338L24.59 8.865c.505 1.3.77 2.677.77 4.133-.053 6.473-5.47 11.802-12.082 11.802"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
})

export default Volkfest
